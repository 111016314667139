"use strict";

class CustomerPropertyController {
    constructor($scope, $timeout, NgMap, $cookies, $localStorage, SSHttpService, SSUtilService, SSSearchService, SSAppService, SSUserService, SSConfigService, SSAlertService, SSStorageService) {

        const self = this;

        self.$scope = $scope;
        self.$timeout = $timeout;
        self.$cookies = $cookies;
        self.$localStorage = $localStorage;

        self.NgMap = NgMap;
        self.SSHttpService = SSHttpService;
        self.SSUtilService = SSUtilService;
        self.SSSearchService = SSSearchService;
        self.SSAppService = SSAppService;
        self.SSUserService = SSUserService;
        self.SSConfigService = SSConfigService;
        self.SSAlertService = SSAlertService;
        self.SSStorageService = SSStorageService;

        // self.SSSearchService.prices = self.formatPricesData(self.SSSearchService.prices);
        // self.SSSearchService.pricesReverse = self.formatPricesData(self.SSSearchService.pricesReverse);

        self.searchProperties = self.SSSearchService.searchProperties;
        self.searchProperties.apiMaxPrice = null;
        self.searchProperties.apiMinPrice = null;

        self.show_options = false;
        self.selectedCountry = self.SSAppService.appServiceObject.selectedCountry;

        self.PARAM_VIEW_CARD = 'card';
        self.PARAM_VIEW_LIST = 'list';
        self.PARAM_VIEW_MAP = 'map';

        self.data = SHOWSUITE.data;
        self.properties = self.data.properties;

        self.SSUserService.setUserFavouriteProperties(SHOWSUITE.userFavouriteProperties);
        self.SSUserService.setUserFavouriteUnits(SHOWSUITE.userFavouriteUnits);

        self.PROPERTY_PRODUCT_MARKETING_SUITE_1D = SHOWSUITE.PROPERTY_PRODUCT_MARKETING_SUITE_1D;
        self.PROPERTY_PRODUCT_MARKETING_SUITE_2D = SHOWSUITE.PROPERTY_PRODUCT_MARKETING_SUITE_2D;
        self.PROPERTY_PRODUCT_MARKETING_SUITE_3D = SHOWSUITE.PROPERTY_PRODUCT_MARKETING_SUITE_3D;
        self.PROPERTY_PRODUCT_MANAGEMENT_SUITE_BUYER_BOOKING = SHOWSUITE.PROPERTY_PRODUCT_MANAGEMENT_SUITE_BUYER_BOOKING;
        self.PROPERTY_PRODUCT_MANAGEMENT_SUITE_AGENT_BOOKING = SHOWSUITE.PROPERTY_PRODUCT_MANAGEMENT_SUITE_AGENT_BOOKING;

        self.PROPERTY_ASSET_TYPE_HERO_IMAGE = SHOWSUITE.PROPERTY_ASSET_TYPE_HERO_IMAGE;
        self.PROPERTY_ASSET_TYPE_LOGO_IMAGE = SHOWSUITE.PROPERTY_ASSET_TYPE_LOGO_IMAGE;

        self.featuredProperties = SHOWSUITE.featuredProperties;
        self.propertyAssetsKeyImgConst = SHOWSUITE.propertyAssetsKeyImgConst;
        //self.propertyComingSoonConst = SHOWSUITE.propertyComingSoonConst;
        self.unitsSoldConst = SHOWSUITE.unitsSoldConst;
        self.viewAs = SHOWSUITE.viewAs;
        self.apiFeatured = SHOWSUITE.apiFeatured;
        self.apiLatest = SHOWSUITE.apiLatest;
        self.apiStatus = SHOWSUITE.apiStatus;
        self.apiCountry = SHOWSUITE.apiCountry;
        self.searchProperties.apiCountry = "702";
        self.apiRegion = SHOWSUITE.apiRegion;

        self.browserUrl = window.location.href;

        //TODO: add pagination
        self.apiSendTotal = false;
        self.apiOffset = 0;
        self.apiLimit = 15;

        self.priceRange = "Min Price - Max Price"
        if (!self.viewAs) {
            self.viewAs = self.PARAM_VIEW_CARD;
        }

        self.featuredAssetsKeyImgs = [];
        if (self.featuredProperties) {
            _.forEach(self.featuredProperties, function (featuredProperty) {
                _.forEach(featuredProperty.property_assets, function (asset) {
                    if (asset.type == 2) {
                        asset.href = '/' + featuredProperty.named_id;
                        asset.name = featuredProperty.name;
                        self.featuredAssetsKeyImgs.push(asset);
                        return false;
                    }
                });
            });
        }

        self.SSAppService.isPageLoaded = true;

        self.broker_properties = [];
        self.getBrokerProperties();

        self.init();

        self.initSearch();
    }

    init() {
        const self = this;
        self.language = self.$localStorage['language'];
        self.videosSelectedTab = 1;

        self.propertyBoxClick = function (property) {
            self.SSAppService.viewProperty(property.named_id);
        };

        self.$scope.$watch(() => self.SSAppService.appServiceObject.selectedCountry, function (selectedCountry) {
            self.selectedCountry = selectedCountry;
        });

        if (self.apiFeatured) {
            self.setApiFeatured(true);
        } else if (self.apiLatest) {
            self.setApiLatest(true);
            // } else if (self.apiStatus == self.propertyComingSoonConst) {
            //   self.setApiStatus(self.propertyComingSoonConst);
        }

        //self.loadSearchGroups();
        //self.loadFeaturedGroups();
        self.loadYoutubeChannelVideos();
        //self.getProperties();

        // Price Range Dropdown
        $("#price-range").click(function (e) {
            e.preventDefault();
            $(".dropdown-range").show();
        });

        $(document).mouseup(function (e) {
            e.preventDefault();
            var container = $(".dropdown-range");

            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $(".dropdown-range").hide();
            }
        });

        //self.setPropertiesOnMap();
        self.SSUserService.updateFavorites();
        console.log(SHOWSUITE.data);

        if (SHOWSUITE.data.hasOwnProperty('language') && SHOWSUITE.data.language != null) {
            self.$localStorage['language'] = SHOWSUITE.data.language.language;
            self.language = SHOWSUITE.data.language.language;
            self.$cookies.put('language-md5', SHOWSUITE.data.language.md5);
        }

        if (SHOWSUITE.data.hasOwnProperty("token_data") && SHOWSUITE.data.token_data != null) {
            //Prepare to display agent information
            if (SHOWSUITE.data.token_data.hasOwnProperty("broker")) {
                self.accessAgent = SHOWSUITE.data.token_data.broker;
            }

            if (SHOWSUITE.data.token_data.hasOwnProperty("agency")) {
                self.accessAgency = SHOWSUITE.data.token_data.agency;
            }

            if (SHOWSUITE.data.token_data.hasOwnProperty("buyer")) {
                self.accessBuyer = SHOWSUITE.data.token_data.buyer;
            }

            if (SHOWSUITE.data.token_data.hasOwnProperty("user")) {
                self.accessUser = SHOWSUITE.data.token_data.user;
            }

            if (SHOWSUITE.data.token_data.hasOwnProperty("user_activation_token")) {
                self.user_activation_token = SHOWSUITE.data.token_data.user_activation_token;
                self.canProceedRegistration = true;
            }

            if (SHOWSUITE.data.token_data.hasOwnProperty("validation_token_type") && SHOWSUITE.data.token_data.validation_token_type == 20) {
                self.showPhoneSuccessfulModal();
            } else {
                //Check is empty or not
                if (SHOWSUITE.data.token_data.hasOwnProperty("token") && SHOWSUITE.data.token_data.token.length > 0 && SHOWSUITE.data.token_data.token[0] == "Token not found.") {
                    self.token_message = "Verification code not found or expired.";
                    self.showErrorMessageModal();
                } else {
                    if (SHOWSUITE.data.token_data.hasOwnProperty("eoi_family")) {
                        self.accessFamily = SHOWSUITE.data.token_data.eoi_family;
                        self.showFamilyAddedSuccessfulModal();
                    } else {
                        self.showAgentAccessSuccessfulModal();
                    }
                }
            }

        }

        if (SHOWSUITE.data.hasOwnProperty("popup") && SHOWSUITE.data.popup != null) {
            //Display popup
            switch (SHOWSUITE.data.popup) {
                case SHOWSUITE.POPUP_ENQUIRY_SUCCESSFUL:
                    //Display enquiry successful popup
                    self.SSUserService.showEnquirySuccessModal();
                    break;

                case SHOWSUITE.POPUP_ERROR:
                    //Display error popup
                    self.SSUserService.showErrorPopupModal();
                    break;

                default:
                    break;
            }
        }

        if (SHOWSUITE.data.hasOwnProperty("token_message") && SHOWSUITE.data.token_message != null) {
            self.token_message = SHOWSUITE.data.token_message;
            self.showErrorMessageModal();
        }

        self.total_property_view_count = SHOWSUITE.data.total;
        self.properties = SHOWSUITE.data.properties;
        self.initLocaleString();
        self.updatePropertiesSoldOut();
        self.limit = parseInt(SHOWSUITE.data.limit);
        self.offset = 0;

        self.developer_reviews = null;
        self.getDeveloperReviews();

        self.developer_profile = null;
        self.developer_logos = SHOWSUITE.data.developer_logos;

        self.$timeout(function () {
            self.updatePropertySaleVisibility();
        }, 3000);
    }

    initLocaleString(){
        const self = this;
        self.localeStrings = [];
        if(self.properties) {
            self.properties.forEach(function (val) {
                self.localeStrings['property.' + val.id + '.name'] = self.toLocaleDictionary('properties', val.id, 'name', val.name);
                self.localeStrings['property.' + val.id + '.location'] = self.toLocaleDictionary('properties', val.id, 'location', val.location);
            });
        }

    }

    toLocaleDictionary(table ,id, column_name, original_value){
        const self = this;

        if(!table || !id || !column_name)
            return original_value;

        if(self.$cookies.get('lang') == 'en')
            return original_value;

        if(self.language){
            let result = self.language.filter(function (row) {

                return table+'.'+id+'.'+column_name+'.'+self.$cookies.get('lang') == row.key;
            });
            if(result.length && result[0].value != '')
                return result[0].value;
            else
                return original_value;
        }
    }

    getDeveloperReviews() {
        const self = this;

        let url = 'developers/developer-reviews';

        loading(true);
        self.SSHttpService.getAPIRequest(url).then(function (response) {
            loading(false);
            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }
            if (response.hasOwnProperty("data") && response.data.hasOwnProperty("reviews")) {
                self.developer_reviews = response.data.reviews;
                console.log(self.developer_reviews);
            }
        });

    }

    viewProfile(id) {
        const self = this;

        self.developer_profile = null;

        self.developer_profile = self.developer_logos.find(x => x.id == id);

        self.showModal = true;
        $('#projectPartnersModal').modal('show');
    }

    viewMore() {
        const self = this;

        self.offset += self.limit;

        let params = {
            'offset': self.offset,
            'limit': self.limit,
            'send_total_count': true
        };

        loading(true);
        self.SSHttpService.getAPIRequest('properties', params).then(function (responseObj) {
            loading(false);
            if (responseObj instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            self.properties = self.properties.concat(responseObj.data.properties);
            self.initLocaleString();
            SHOWSUITE.data.properties = self.properties;
        });
    }

    getPropertyHeroImageUrl(property_assets) {
        const self = this;

        if (!property_assets) {
            return;
        }

        let asset = property_assets.find(x => x.type == self.PROPERTY_ASSET_TYPE_HERO_IMAGE);

        if (!asset) {
            return;
        }

        return self.SSAppService.getAssetUrl(asset.file_uri);
    }

    getPropertyLogoImageUrl(property_assets) {
        const self = this;

        if (!property_assets) {
            return '/images/placeholder.jpg';
        }

        let asset = property_assets.find(x => x.type == self.PROPERTY_ASSET_TYPE_LOGO_IMAGE);

        if (!asset) {
            return '/images/placeholder.jpg';
        }

        return self.SSAppService.getAssetUrl(asset.file_uri);
    }

    showAgentAccessSuccessfulModal() {
        const self = this;

        self.hideAllModal();

        self.displayAgentAccessSuccessful = true;

        $('#agentAccessModal').modal('show');
    }

    showPhoneSuccessfulModal() {
        const self = this;

        self.hideAllModal();

        self.displayPhoneUpdateSuccessful = true;

        $('#phoneUpdateModal').modal('show');
    }

    showFamilyAddedSuccessfulModal() {
        const self = this;

        self.hideAllModal();

        self.displayFamilyAddedSuccessful = true;

        $('#familyAddedModal').modal('show');
    }

    showErrorMessageModal() {
        const self = this;

        self.hideAllModal();

        self.displayErrorMessage = true;

        $('#errorMessageModal').modal('show');
    }

    hideAllModal() {
        const self = this;

        self.displayAgentAccessSuccessful = false;
        self.displayUserRegistration = false;
        self.displayFamilyAddedSuccessful = false;
        self.displayErrorMessage = false;

        if ($('#agentAccessModal').is(':visible')) $('#agentAccessModal').modal('hide');
        if ($('#userRegistrationModal').is(':visible')) $('#userRegistrationModal').modal('hide');
        if ($('#familyAddedModal').is(':visible')) $('#familyAddedModal').modal('hide');
        if ($('#errorMessageModal').is(':visible')) $('#errorMessageModal').modal('hide');
    }

    proceedRegistration() {
        const self = this;

        self.hideAllModal();

        if (self.canProceedRegistration) {
            self.displayUserRegistration = true;

            $('#userRegistrationModal').modal('show');
        }
    }

    confirmRegistration() {
        const self = this;

        //Validation
        let params = {
            'activation_token': self.user_activation_token,
            'user_password': self.userPassword,
            'user_password_confirmation': self.userPasswordConfirmation
        };

        self.resetPasswordInProcess = true;
        self.resetPasswordError = null;
        self.resetPasswordSuccess = null;
        self.userPasswordErr = null;
        self.userPasswordConfirmationErr = null;

        if (!self.SSUserService.checkPasswordPolicy(self.userPassword)) {
            self.resetPasswordInProcess = false;
            self.resetPasswordError = 'Invalid Password';
            self.userPasswordErr = 'Your password must be at least 8 alphanumeric characters with at least one lowercase letter, one uppercase letter, a number, a symbol and not more than 14 alphanumeric characters.';
            return;
        }

        self.SSHttpService.putAPIRequest('reset-password', params).then(
            function (responseObj) {
                self.resetPasswordInProcess = false;
                if (responseObj instanceof Error) {
                    let errorData = responseObj.data;

                    self.resetPasswordError = responseObj.message;

                    if (_.has(errorData, 'user_password')) {
                        self.userPasswordErr = errorData.user_password[0];
                    }
                    if (_.has(errorData, 'user_password_confirmation')) {
                        self.userPasswordConfirmationErr = errorData.user_password_confirmation[0];
                    }

                    return;
                }

                self.SSUserService.setUser(responseObj.data.user);
                self.hideAllModal();
                self.SSAlertService.success("Success!", "Thank you for signing up with Showsuite.");
            });
    }

    getProperties() {
        const self = this;

        let url = 'properties';

        //pagination params
        url += '?offset=' + self.apiOffset;
        url += '&limit=' + self.apiLimit;
        url += '&send_total_count=' + self.apiSendTotal;

        //region params
        let regionName = null;
        if (self.apiRegion && self.apiCountry) {
            url += '&country=' + self.apiCountry + '&region=' + self.apiRegion;
            regionName = self.SSAppService.getRegionName(self.apiCountry, self.apiRegion);
        }

        //params that only be set one at a time [Featured or Latest or Coming Soon]
        if (self.apiFeatured) {
            url += '&featured=' + true;
        } else if (self.apiLatest) {
            url += '&latest=' + true;
            // } else if (self.apiStatus == self.propertyComingSoonConst) {
            //   url += '&status=' + self.propertyComingSoonConst;
        }

        self.properties = null;
        self.userMessage = self.getUserLoadingMessage(regionName);

        self.SSUtilService.updateWindowUrl(self.browserUrl);

        self.SSHttpService.getAPIRequest(url).then(
            function (responseObj) {

                if (responseObj instanceof Error) {
                    self.userMessage = self.getUserErrorMessage(regionName);
                    return;
                }

                self.properties = responseObj.data.properties;
                self.initLocaleString();

                self.userMessage = self.getUserSuccessMessage(regionName);

                self.setPropertiesOnMap();
            });
    }

    showLoginModal() {
        const self = this;
        self.hideLoginModal();
        self.loginModal = true;
        $('#loginModal').modal('show');
    }

    /**
     * API params setters.
     */
    setApiFeatured(featured, getProperties = false) {
        const self = this;

        //flush others
        self.apiLatest = null;
        self.browserUrl = self.SSUtilService.addOrUpdateUrlParam(self.browserUrl, 'latest', null);
        self.apiStatus = null;
        self.browserUrl = self.SSUtilService.addOrUpdateUrlParam(self.browserUrl, 'status', null);

        self.apiFeatured = featured;
        self.browserUrl = self.SSUtilService.addOrUpdateUrlParam(self.browserUrl, 'featured', self.apiFeatured);

        if (getProperties) {
            self.getProperties();
        }
    }

    setApiLatest(latest, getProperties = false) {
        const self = this;

        //flush others
        self.apiFeatured = null;
        self.browserUrl = self.SSUtilService.addOrUpdateUrlParam(self.browserUrl, 'featured', null);
        self.apiStatus = null;
        self.browserUrl = self.SSUtilService.addOrUpdateUrlParam(self.browserUrl, 'status', null);

        self.apiLatest = latest;
        self.browserUrl = self.SSUtilService.addOrUpdateUrlParam(self.browserUrl, 'latest', self.apiLatest);

        if (getProperties) {
            self.getProperties();
        }
    }

    setApiStatus(status, getProperties = false) {
        const self = this;

        //flush others
        self.apiFeatured = null;
        self.browserUrl = self.SSUtilService.addOrUpdateUrlParam(self.browserUrl, 'featured', null);
        self.apiLatest = null;
        self.browserUrl = self.SSUtilService.addOrUpdateUrlParam(self.browserUrl, 'latest', null);

        self.apiStatus = status;
        self.browserUrl = self.SSUtilService.addOrUpdateUrlParam(self.browserUrl, 'status', self.apiStatus);

        if (getProperties) {
            self.getProperties();
        }
    }

    setApiRegionAndCountry(country, region, getProperties = false) {
        const self = this;

        //ensure both are set
        if (!country || !region) {
            country = null;
            region = null;
        }

        self.apiCountry = country;
        self.apiRegion = region;
        self.browserUrl = self.SSUtilService.addOrUpdateUrlParam(self.browserUrl, 'country', self.apiCountry);
        self.browserUrl = self.SSUtilService.addOrUpdateUrlParam(self.browserUrl, 'region', self.apiRegion);

        if (getProperties) {
            self.getProperties();
        }
    }

    /**
     * User messages.
     */
    getUserLoadingMessage(regionName) {
        const self = this;
        let loadingMsg;

        if (self.apiFeatured) {
            loadingMsg = 'Fetching featured projects';
        } else if (self.apiLatest) {
            loadingMsg = 'Fetching new projects';
            // } else if (self.apiStatus == self.propertyComingSoonConst) {
            //   loadingMsg = 'Fetching coming soon projects';
        } else {
            loadingMsg = 'Fetching projects';
        }

        loadingMsg = regionName ? loadingMsg + ' in ' + regionName : loadingMsg;
        return loadingMsg + '...';
    }

    getUserErrorMessage(regionName) {
        const self = this;
        let errorMsg;

        if (self.apiFeatured) {
            errorMsg = 'Unable to fetch featured projects';
        } else if (self.apiLatest) {
            errorMsg = 'Unable to fetch new projects';
            // } else if (self.apiStatus == self.propertyComingSoonConst) {
            //   errorMsg = 'Unable to fetch coming soon projects';
        } else {
            errorMsg = 'Unable to fetch projects';
        }

        return regionName ? errorMsg + ' in ' + regionName : errorMsg;
    }

    getUserSuccessMessage(regionName) {
        const self = this;
        let successMsg;

        if (self.apiFeatured) {
            successMsg = 'FEATURED PROJECTS';
        } else if (self.apiLatest) {
            successMsg = 'NEW PROJECTS';
            // } else if (self.apiStatus == self.propertyComingSoonConst) {
            //   successMsg = 'COMING SOON PROJECTS';
        } else {
            successMsg = 'ALL PROJECTS';
        }

        return regionName ? successMsg + ' in ' + regionName : successMsg;
    }

    /**
     * Property view related.
     */
    setPropertiesOnMap() {
        const self = this;

        if (self.map != null)
            return;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('HM_PG_MAP_CLICKS', {'event_category': 'Homepage'});

        self.NgMap.getMap().then(function (map) {
            self.map = map;

            self.$timeout(function () {
                let infowindow = new google.maps.InfoWindow();
                let marker, i;

                for (i = 0; i < self.properties.length; i++) {
                    let propertyImg = self.SSAppService.getPropertyHeroThumbUrl(self.properties[i]);
                    let propertyRegion = self.SSAppService.getRegionName(self.properties[i].country, self.properties[i].region);
                    let propertyTenure = self.properties[i].tenure;
                    let propertyRemainingUnits = self.SSAppService.getRemainingUnits(self.properties[i]);
                    let propertyTotalUnits = self.SSAppService.getTotalUnits(self.properties[i]);

                    let infoWindowContent = '' +
                        '<a target="_blank" href="/' + self.properties[i].named_id + '">' +
                        '  <table>' +
                        '    <tr>' +
                        '      <td><figure class="pull-left" style="width:175px;margin-right:10px"><img src="' + propertyImg + '"></figure></td>' +
                        '      <td>' +
                        '        <aside class="regular-font project-details pull-left">' +
                        '          <span class="bold-font">' + self.properties[i].name + '</span>' +
                        '          <p>' + propertyRegion + '<br>Tenure: ' + propertyTenure + '<br>Units Balance : ' + propertyRemainingUnits + ' / ' + propertyTotalUnits + '</p>' +
                        '        </aside>' +
                        '      </td>' +
                        '    </tr>' +
                        '  </table>' +
                        '</a>';

                    marker = new google.maps.Marker({
                        position: {lat: self.properties[i].lat, lng: self.properties[i].long},
                        animation: google.maps.Animation.DROP,
                        label: self.properties[i].name[0],
                        map: self.map
                    });

                    google.maps.event.addListener(marker, 'click', (function (marker, i) {
                        return function () {
                            infowindow.setContent(infoWindowContent);
                            infowindow.open(self.map, marker);
                        }
                    })(marker, i));
                    google.maps.event.trigger(self.map, 'resize');
                }
            });
        });
    }

    setViewAs(view) {
        const self = this;

        self.viewAs = view;

        if (self.viewAs == self.PARAM_VIEW_MAP)
            self.setPropertiesOnMap();

        self.browserUrl = self.SSUtilService.addOrUpdateUrlParam(self.browserUrl, 'view', view);
        self.SSUtilService.updateWindowUrl(self.browserUrl);

    }

    // Search options hide/show for mobile
    toggleSearchOptions() {
        jQuery('.form-hide-mobile').toggleClass('active');
        jQuery("#advanced-search-dd").toggleClass('fa-plus-circle');
        jQuery("#advanced-search-dd").toggleClass('fa-minus-circle');
    }

    quickSearch() {
        const self = this;

        self.SSSearchService.hideAdvanceSearch();
        self.SSSearchService.resetSq();
        window.location.href = self.SSSearchService.getQuickSearchUrlWithParams();
    }

    advanceSearch() {
        const self = this;

        self.SSSearchService.showAdvanceSearch();
        self.SSSearchService.resetSq();
        window.location.href = self.SSSearchService.getQuickSearchUrlWithParams();
    }

    /* Youtube Videos */
    loadYoutubeChannelVideos() {
        const self = this;

        self.videos1 = self.data.videos1;
        self.videos2 = self.data.videos2;
        self.videos3 = self.data.videos3;
    }

    showYoutubeVideoPlayer(videoId) {
        toggle_youtube_video_player(videoId);
    }

    /* Youtube Videos */

    /* Search Groups */
    loadSearchGroups() {
        const self = this;

        self.SSHttpService.getRequest(`more/get-cms-page-recs/search_groups`).then(function (response) {
            if (response instanceof Error) {
                self.SSUtilService.logError(response);
                return;
            }
            self.search_groups = response.data;
        });
    }

    /* Search Groups */

    /* Featured Groups */
    loadFeaturedGroups() {
        const self = this;

        // Set default image
        self.featured_groups = [
            {
                file_uri: '/images/carouselhome1.jpg?placehold=1',
                order: 1
            }
        ];

        self.SSHttpService.getRequest(`more/get-cms-page-recs/featured`).then(function (response) {
            if (response instanceof Error) {
                self.SSUtilService.logError(response);
                return;
            }

            if (response.data && response.data.length) {
                self.featured_groups = response.data;
            }
        });
    }

    /* Featured Groups */

    /* Price Range */
    formatPricesData(prices) {
        const self = this;
        for (let i = 0; i < prices.length; i++) {
            prices[i] = self.SSAppService.getFormattedAmount(prices[i]);
        }
        return prices;
    }

    setMinPrice(price) {
        const self = this;

        self.searchProperties.apiMinPrice = price;
        self.formatPriceRangeValue();
    }

    setMaxPrice(price) {
        const self = this;

        self.searchProperties.apiMaxPrice = price;
        self.formatPriceRangeValue();
    }

    formatPriceRangeValue() {
        const self = this;

        let minPrice = self.searchProperties.apiMinPrice ? self.SSAppService.getFormattedAmount(self.searchProperties.apiMinPrice) : 'Any';
        let maxPrice = self.searchProperties.apiMaxPrice ? self.SSAppService.getFormattedAmount(self.searchProperties.apiMaxPrice) : 'Any';

        return minPrice + " - " + maxPrice;
    }

    /* Price Range */

    findProperty(propertyId) {
        const self = this;

        let property = null;
        if (self.properties) {
            jQuery.each(self.properties, function (i, e) {
                if (e.id == propertyId) {
                    property = e;
                    return false;
                }
            });
        }

        return property;
    }

    homePageSearch() {
        const self = this;

        let params = {
            sq_type: 1,
            keyword: self.searched_value,
            send_total_count: true,
            offset: 0,
            limit: 15
        };

        $.each(self.other_keywords, function (index, keyword) {
            switch (keyword.type) {
                case self.unit_type_keyword:
                    params["unit_type"] = keyword.value;
                    break;
                case self.psf_keyword:
                    params["min_psf"] = keyword.value;
                    break;
                case self.min_price_keyword:
                    params["min_price"] = keyword.value;
                    break;
                case self.max_price_keyword:
                    params["max_price"] = keyword.value;
                    break;
                case self.property_type_keyword:
                    params["property_type"] = keyword.value;
                    break;
                case self.tenure_keyword:
                    params["tenure"] = keyword.value;
                    break;
                // case self.from_completion_date_keyword:
                //   params["unit_type"] = keyword.value;
                //   break;
                // case self.to_completion_date_keyword:
                //   params["unit_type"] = keyword.value;
                //   break;
                default:
                    console.log("shouldn't happen!!");
                    return;
                    break;
            }
        });

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('HM_PG_SEARCH', {'event_category': 'Homepage'});

        loading(true);
        self.SSHttpService.getAPIRequest('search', params).then(function (response) {
            loading(false);
            if (response instanceof Error) {
                self.SSUtilService.logError(response);
                return;
            }

            self.properties = response.data.properties;
            self.total_property_view_count = response.data.total;
            self.searched_value = "";
            self.updatePropertiesSoldOut();
            self.initLocaleString();
            self.updatePropertySaleVisibility();
        });
    }

    updateOtherKeywords(keyword_type, keyword_obj) {
        const self = this;

        if (self.other_keywords == undefined || !self.other_keywords) {
            self.other_keywords = [];
        }

        if (keyword_obj) {
            self.removeOtherKeywords(keyword_type);
            let temp_name = "";

            if (keyword_type == self.min_price_keyword) {
                temp_name = "Min: " + keyword_obj.name;
            } else if (keyword_type == self.max_price_keyword) {
                temp_name = "Max: " + keyword_obj.name;
            } else {
                temp_name = keyword_obj.name;
            }

            self.other_keywords.push({
                type: keyword_type,
                value: keyword_obj.id,
                name: temp_name,
            });
        } else {
            self.removeOtherKeywords(keyword_type);
        }
    }

    removeOtherKeywords(keyword_type) {
        const self = this;

        self.other_keywords = $.grep(self.other_keywords, function (e) {
            return e.type != keyword_type;
        });
    }

    initSearch() {
        const self = this;

        self.other_keywords = [];

        self.unit_type_keyword = "unit_type";
        self.psf_keyword = "psf";
        self.min_price_keyword = "min_price";
        self.max_price_keyword = "max_price";
        self.property_type_keyword = "property_type";
        self.tenure_keyword = "tenure";
        self.from_completion_date_keyword = "from_completion_date";
        self.to_completion_date_keyword = "to_completion_date";

        self.psfs = [
            {
                id: 500,
                name: '$500 PSF'
            },
            {
                id: 600,
                name: '$600 PSF'
            },
            {
                id: 700,
                name: '$700 PSF'
            },
            {
                id: 800,
                name: '$800 PSF'
            },
            {
                id: 900,
                name: '$900 PSF'
            },
            {
                id: 1000,
                name: '$1000 PSF'
            },
            {
                id: 1100,
                name: '$1,100 PSF'
            },
            {
                id: 1200,
                name: '$1,200 PSF'
            },
            {
                id: 1300,
                name: '$1,300 PSF'
            },
            {
                id: 1400,
                name: '$1,400 PSF'
            },
            {
                id: 1500,
                name: '$1,500 PSF'
            },
            {
                id: 1600,
                name: '$1,600 PSF'
            },
            {
                id: 1700,
                name: '$1,700 PSF'
            },
            {
                id: 1800,
                name: '$1,800 PSF'
            },
            {
                id: 1900,
                name: '$1,900 PSF'
            },
            {
                id: 2000,
                name: '$2,000 PSF'
            },
            {
                id: 2100,
                name: '$2,100 PSF'
            },
            {
                id: 2200,
                name: '$2,200 PSF'
            },
            {
                id: 2300,
                name: '$2,300 PSF'
            },
            {
                id: 2400,
                name: '$2,400 PSF'
            },
            {
                id: 2500,
                name: '$2,500 PSF'
            },
            {
                id: 2600,
                name: '$2,600 PSF'
            },
            {
                id: 2700,
                name: '$2,700 PSF'
            },
            {
                id: 2800,
                name: '$2,800 PSF'
            },
            {
                id: 2900,
                name: '$2,900 PSF'
            },
            {
                id: 3000,
                name: '$3,000 PSF'
            }
        ];

        self.prices = [
            {
                id: 200000,
                name: '$200,000'
            },
            {
                id: 300000,
                name: '$300,000'
            },
            {
                id: 400000,
                name: '$400,000'
            },
            {
                id: 500000,
                name: '$500,000'
            },
            {
                id: 600000,
                name: '$600,000'
            },
            {
                id: 700000,
                name: '$700,000'
            },
            {
                id: 800000,
                name: '$800,000'
            },
            {
                id: 900000,
                name: '$900,000'
            },
            {
                id: 1000000,
                name: '$1,000,000'
            },
            {
                id: 1200000,
                name: '$1,200,000'
            },
            {
                id: 1500000,
                name: '$1,500,000'
            },
            {
                id: 1800000,
                name: '$1,800,000'
            },
            {
                id: 2000000,
                name: '$2,000,000'
            },
            {
                id: 3000000,
                name: '$3,000,000'
            },
            {
                id: 4000000,
                name: '$4,000,000'
            },
            {
                id: 5000000,
                name: '$5,000,000'
            },
            {
                id: 6000000,
                name: '$6,000,000'
            },
            {
                id: 7000000,
                name: '$7,000,000'
            },
            {
                id: 8000000,
                name: '$8,000,000'
            },
            {
                id: 9000000,
                name: '$9,000,000'
            },
            {
                id: 10000000,
                name: '$10,000,000'
            },
            {
                id: 11000000,
                name: '$11,000,000'
            },
            {
                id: 12000000,
                name: '$12,000,000'
            },
            {
                id: 13000000,
                name: '$13,000,000'
            },
            {
                id: 14000000,
                name: '$14,000,000'
            },
            {
                id: 15000000,
                name: '$15,000,000'
            },
            {
                id: 16000000,
                name: '$16,000,000'
            },
            {
                id: 17000000,
                name: '$17,000,000'
            },
            {
                id: 18000000,
                name: '$18,000,000'
            },
            {
                id: 19000000,
                name: '$19,000,000'
            },
            {
                id: 20000000,
                name: '$20,000,000'
            },
            {
                id: 21000000,
                name: '$20,000,000 & Above'
            }
        ];

        self.tenures = [
            {
                id: 99,
                name: '99 Years'
            },
            {
                id: 103,
                name: '103 Years'
            },
            {
                id: 999,
                name: '999 Years'
            },
            {
                id: 'Freehold',
                name: 'Freehold'
            }
        ];

        self.$timeout(function () {
            $("select.search-select").niceSelect('update');
        });
    }

    updatePropertiesSoldOut() {
        const self = this;

        if (self.properties) {
            $.each(self.properties, function (index, property) {
                property.available_balance = self.SSAppService.getRemainingUnits(property);
            });
        }
    }

    getBrokerProperties() {
        const self = this;

        if (self.SSUserService.isBroker()) {
            loading(true);
            self.SSUserService.getBrokerAgencyWithProperty().then(function (response) {
                loading(false);
                if (response instanceof Error) {
                    self.SSAlertService.parseAndDisplayError(response);
                    return;
                }
                if (!(response.data && response.data.properties)) {
                    return;
                }

                self.broker_properties = response.data.properties;
            });
        }
    }

    updatePropertySaleVisibility() {
        const self = this;

        $.each(self.properties, function (index, property) {
            property.display_statistics = false;

            let sale_statistics_visibility = self.SSAppService.getPropertySetting(property, 'sale_statistics_visibility');

            if (sale_statistics_visibility !== null && typeof sale_statistics_visibility === 'object' && sale_statistics_visibility.hasOwnProperty("value") && sale_statistics_visibility.value != null) {
                sale_statistics_visibility = sale_statistics_visibility.value;
            }

            if (sale_statistics_visibility == SHOWSUITE.AVAILABILITY_BROKERS_TEAM || sale_statistics_visibility == SHOWSUITE.AVAILABILITY_TEAM_LEADS) {
                if (self.SSUserService.isBroker()) {
                    $.each(self.broker_properties, function (index, _property) {
                        if (_property.id === property.id) {
                            property.display_statistics = true;
                            return;
                        }
                    });
                }
            } else if (sale_statistics_visibility == SHOWSUITE.AVAILABILITY_BROKERS_ALL) {
                if (self.SSUserService.isBroker()) {
                    property.display_statistics = true;
                }
            } else if (sale_statistics_visibility == SHOWSUITE.AVAILABILITY_PUBLIC) {
                property.display_statistics = true;
            }
        });
    }

    openFeatureImage(content) {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('HM_PG_CAROUSAL_CLICKS', {'event_category': 'Homepage'});

        self.SSUtilService.openUrlInNewTab(content);
    }

    viewProject(property) {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('HM_PG_SCROLL_CLICKS', {'event_category': 'Homepage', 'property': property.name});

        window.location.href = "/" + property.named_id;
    }

    displayShareModal(property) {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('HM_PG_PRJ_SHARE', {'event_category': 'Homepage', 'property': property.name});

        self.SSAppService.showShareModal(property.named_id)
    }

    updatePropertyFavourite(property) {
        const self = this;

        //Add to Firebase Analytics Event
        if(self.SSUserService.isPropertyFavourite(property.id))
            firebase.analytics().logEvent('HM_PG_REM_FAV_CLICKS', {'event_category': 'Homepage', 'property': property.name});
        else
            firebase.analytics().logEvent('HM_PG_FAV_CLICKS', {'event_category': 'Homepage', 'property': property.name});

        self.SSUserService.updatePropertyFavorite(property.id)
    }

    trackListViewAnalytics() {
        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('HM_PG_LISTVW_CLICKS', {'event_category': 'Homepage'});
    }

    compareProperty(property) {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('HM_PG_COMPARE_CLICKS', {'event_category': 'Homepage', 'property': property.name});

        self.SSAppService.compareProperty(property.id);
    }
}

CustomerPropertyController.$inject = ['$scope', '$timeout', 'NgMap', '$cookies', '$localStorage', 'SSHttpService', 'SSUtilService', 'SSSearchService', 'SSAppService', 'SSUserService', 'SSConfigService', 'SSAlertService', 'SSStorageService'];
app.controller('CustomerPropertyController', CustomerPropertyController);
