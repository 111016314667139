"use strict";

class CustomerPropertyEnquiryController {
    constructor($scope, $timeout, $cookies, $localStorage, SSHttpService, SSUtilService, SSSearchService, SSAppService, SSUserService, SSConfigService, SSAlertService, SSStorageService) {

        const self = this;

        self.$scope = $scope;
        self.$timeout = $timeout;
        self.$cookies = $cookies;
        self.$storage = $localStorage;

        self.SSHttpService = SSHttpService;
        self.SSUtilService = SSUtilService;
        self.SSSearchService = SSSearchService;
        self.SSAppService = SSAppService;
        self.SSUserService = SSUserService;
        self.SSConfigService = SSConfigService;
        self.SSAlertService = SSAlertService;
        self.SSStorageService = SSStorageService;

        self.data = SHOWSUITE.data;
        self.property = self.data.property;

        self.init();
    }

    init(){
        const self = this;

        self.language = self.$storage['language'];

        self.initLocaleString();
        self.initEnquiryFields();
    }

    initEnquiryFields() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_ENQUIRE_CLICKS', {'event_category': 'Project Details', 'property': self.property.name});

        // Register
        self.SSUserService.registerParams = {
            _as_agent: false,
            _from_enquiry: true,
        };

        let unit_id = self.unit? self.unit.id : null;

        self.SSUserService.enquireParams = {
            property_id: self.property.id,
            unit_id: unit_id,
            register_from_share_code: self.SSUtilService.getURLParameter('share_code'),
            txt_message: 'I am interested to know more about this development.\nPlease call or message me.\n\nThank you.'
        };

        self.SSUserService.enquireErrors = {};
        self.SSUserService.registerErrors = {};

        self.SSUserService.maskPhoneNumber(self, 'ctrl.SSUserService.');
    }

    initLocaleString(){
        const self = this;
        self.localeStrings = [];

        self.localeStrings['property.' + self.property.id + '.name'] = self.toLocaleDictionary('properties',self.property.id,'name',self.property.name);
    }

    toLocaleDictionary(table ,id, column_name, original_value){
        const self = this;

        if(!table || !id || !column_name)
            return original_value;

        if(self.$cookies.get('lang') == 'en')
            return original_value;

        if(self.language){
            let result = self.language.filter(function (row) {

                return table+'.'+id+'.'+column_name+'.'+self.$cookies.get('lang') == row.key;
            });
            if(result.length && result[0].value != '')
                return result[0].value;
            else
                return original_value;
        }
    }

}

CustomerPropertyEnquiryController.$inject = ['$scope', '$timeout', '$cookies', '$localStorage', 'SSHttpService', 'SSUtilService', 'SSSearchService', 'SSAppService', 'SSUserService', 'SSConfigService', 'SSAlertService', 'SSStorageService'];
app.controller('CustomerPropertyEnquiryController', CustomerPropertyEnquiryController);
