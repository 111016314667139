"use strict";

class CustomerPropertyDetailsController {
    constructor($scope, $rootScope, $localStorage, $timeout, $window, $cookies, NgMap, SSHttpService, SSUtilService, SSAppService, SSUserService, Fullscreen, SSDisclaimerService) {

        const self = this;

        self.$scope = $scope;
        self.$rootScope = $rootScope;
        self.$storage = $localStorage;
        self.$timeout = $timeout;
        self.$window = $window;
        self.$cookies = $cookies;

        self.Fullscreen = Fullscreen;
        self.SSHttpService = SSHttpService;
        self.SSUtilService = SSUtilService;
        self.SSAppService = SSAppService;
        self.SSUserService = SSUserService;
        self.SSDisclaimerService = SSDisclaimerService;

        self.data = SHOWSUITE.data;
        self.property = self.data.property;
        self.unitsData = self.data.property.units_data2;
        self.initLocaleString();
        self.callButtonActive = false;

        self.PROPERTY_STATUS_LISTED = SHOWSUITE.PROPERTY_STATUS_LISTED;

        self.ASSET_TYPE = {
            LOGO_IMAGE: 1,
            HERO_IMAGE: 2,
            KEY_IMAGE: 3,
            FACILITY_MAGE: 4,
            SITE_PLAN_A_IMAGE: 5,
            SITE_PLAN_B_IMAGE: 6,
            AD_VIDEO: 7,
            LAYOUT_VIDEO: 8,
            BROCHURE_PDF: 9,
            FLOOR_PLAN_PDF: 10,
            LIFE_STYLE_IMAGE: 11,
            MAP_IMAGE: 16
        };
        self.showPhoneModal = false;
        self.showSocialModal = false;
        self.air_view_3d = false;

        self.shareCode = '';

        self.map = null;
        self.NgMap = NgMap;

        self.PROPERTY_TYPE_EXECUTIVE_CONDO = SHOWSUITE.PROPERTY_TYPE_EXECUTIVE_CONDO;

        self.PROPERTY_PRODUCT_MARKETING_SUITE_1D = SHOWSUITE.PROPERTY_PRODUCT_MARKETING_SUITE_1D;
        self.PROPERTY_ASSET_TYPE_360_IMAGE = SHOWSUITE.PROPERTY_ASSET_TYPE_360_IMAGE;
        self.PROPERTY_ASSET_TYPE_AIR_VIEW_IMAGE = SHOWSUITE.PROPERTY_ASSET_TYPE_AIR_VIEW_IMAGE;
        self.PROPERTY_ASSET_TYPE_AIR_VIEW_3D = SHOWSUITE.PROPERTY_ASSET_TYPE_AIR_VIEW_3D;
        self.PROPERTY_ASSET_TYPE_SHOW_GALLERY_360 = SHOWSUITE.PROPERTY_ASSET_TYPE_SHOW_GALLERY_360;
        self.PROPERTY_ASSET_TYPE_ARCHITECTURAL_360 = SHOWSUITE.PROPERTY_ASSET_TYPE_ARCHITECTURAL_360;

        self.UNIT_STATUS_SOLD = SHOWSUITE.UNIT_STATUS_SOLD;
        self.UNIT_STATUS_DRAFT = SHOWSUITE.UNIT_STATUS_DRAFT;

        self.init();

        self.trackGAShowGalleryFirstTime = false;
        self.trackGA360FirstTime = false;
        self.trackGAArchitecturalFirstTime = false;
        self.trackGAAirViewFirstTime = false;

        let monitor = setInterval(function(){
            let elem = document.activeElement;
            if(elem && elem.tagName == 'IFRAME'){
                console.log(elem.id);
                switch (elem.id) {
                    case 'iframeShowGallery360Imgs':
                        self.trackGAShowGallery360Imgs();
                        break;

                    case 'iframe360Imgs':
                        self.trackGA360Event();
                        break;

                    case 'iframeArchitectural360Imgs':
                        self.trackGAArchitectural360Imgs();
                        break;

                    case 'iframeAirviewImgs':
                        self.trackGAAirViewImgs();
                        break;

                    default:
                        break;
                }
                elem.blur();
            }
        }, 500);
    }

    init() {
        const self = this;
        self.language = self.$storage['language'];
        self.loadingText = 'Loading...';
        self.viewMedia = 'model3dLoading';
        // self.projectView(self.data.property.id);
        self.getPropertyDetails();
        self.getPropertyUnitsStats();
        self.getPropertySetting();
        self.getUnitData();
        if (self.data.share_code) {
            self.prepareLoadShareCode();
        }
        self.checkCallButtonActive();

        // Detecting fullscreen change
        self.$rootScope.$on('FBFullscreen.change', function ($event, isEnabled) {
            self.$timeout(function () {
                self.isFullscreen = isEnabled;
            });
        });

        self.scrollToLink();
        $('#return-to-top').hide();

        $(window).scroll(function() {
          if ($(this).scrollTop() >= 300) {        // If page is scrolled more than 300px
              $('#return-to-top').fadeIn(200);    // Fade in the arrow
          } else {
              $('#return-to-top').fadeOut(200);   // Else fade out the arrow
          }
        });

        $('#return-to-top').click(function() {      // When arrow is clicked
          $('body,html').animate({
              scrollTop : 0                       // Scroll to top of body
          }, 500);
        });

        self.initMrtColorCodes();
        // self.trackPropertyViews();
        self.checkUnitTypesVisibility();

        if (SHOWSUITE.data.view_mode) {
            self.$timeout(function () {
                let scrollToDiv = null;
                switch(SHOWSUITE.data.view_mode) {
                    case 'video':
                        scrollToDiv = document.getElementById('video');
                        if (SHOWSUITE.data.slide) {
                            let slide = parseInt(SHOWSUITE.data.slide);
                            if (slide) {
                                self.$timeout(function () {
                                    $('#detailed-view-carousel-video').carousel(slide);
                                });
                            }
                        }
                        break;

                    case '3dmodel':
                        scrollToDiv = document.getElementById('3d-model');
                        break;

                    case '360':
                        scrollToDiv = document.getElementById('360photo');
                        break;

                    case 'airview':
                        scrollToDiv = document.getElementById('air-view');
                        break;

                    case 'storeyview':
                        scrollToDiv = document.getElementById('storey-view');
                        break;

                    case 'showGallery360':
                        scrollToDiv = document.getElementById('showGallery360');
                        break;

                    case 'architectural360':
                        scrollToDiv = document.getElementById('architectural360');
                        break;

                    default:
                        console.log("Incorrect view mode, do nothing here.");
                        break;
                }

                if (scrollToDiv) {
                    scrollToDiv.scrollIntoView();
                }
            }, 500);
        }
    }

    initMrtColorCodes(){
        const self = this;

        self.mrt_color_code = {
            "NE" : "#9900AB",
            "DT" : "#005EC4",
            "TE" : "#784008",
            "CC" : "#FA9E0D",
            "CE" : "#FA9E0D",
            "NS" : "#D42E12",
            "CG" : "#009645",
            "EW" : "#009645",
            "JE" : "#1E888C",
            "JS" : "#1E888C",
            "JW" : "#1E888C",
            "BP" : "#708270",
            "SE" : "#708270",
            "STC" : "#708270",
            "SW" : "#708270",
            "PTC" : "#708270",
            "PE" : "#708270",
            "PW" : "#708270",
        };
    }

    initLocaleString(){
        const self = this;
        self.localeStrings = [];

        self.localeStrings['property.' + self.property.id + '.facilities'] = self.toLocaleDictionary('properties',self.property.id,'facilities',self.property.facilities);
        self.localeStrings['property.' + self.property.id + '.clubhouse_facilities'] = self.toLocaleDictionary('properties',self.property.id,'clubhouse_facilities',self.property.clubhouse_facilities);
        self.localeStrings['property.' + self.property.id + '.name'] = self.toLocaleDictionary('properties',self.property.id,'name',self.property.name);
        self.localeStrings['property.' + self.property.id + '.location'] = self.toLocaleDictionary('properties',self.property.id,'location',self.property.location);
        self.localeStrings['property.' + self.property.id + '.developer_legal_name'] = self.toLocaleDictionary('properties',self.property.id,'developer_legal_name',self.property.developer_legal_name);
        self.localeStrings['property.' + self.property.id + '.showflat_address'] = self.toLocaleDictionary('properties',self.property.id,'showflat_address',self.property.showflat_address);
        self.localeStrings['property.' + self.property.id + '.description'] = self.toLocaleDictionary('properties',self.property.id,'description',self.property.description);
        self.localeStrings['property.' + self.property.id + '.short_description'] = self.toLocaleDictionary('properties',self.property.id,'description',self.property.short_description);
        self.localeStrings['property.' + self.property.id + '.nearby_bus_station'] = self.toLocaleDictionary('properties',self.property.id,'nearby_bus_station',self.property.nearby_bus_station);
        self.localeStrings['property.' + self.property.id + '.highways'] = self.toLocaleDictionary('properties',self.property.id,'highways',self.property.highways);
        self.localeStrings['property.' + self.property.id + '.shopping_malls'] = self.toLocaleDictionary('properties',self.property.id,'shopping_malls',self.property.shopping_malls);
        self.localeStrings['property.' + self.property.id + '.recreation'] = self.toLocaleDictionary('properties',self.property.id,'recreation',self.property.recreation);
        self.localeStrings['property.' + self.property.id + '.places_of_interest'] = self.toLocaleDictionary('properties',self.property.id,'places_of_interest',self.property.places_of_interest);
        self.localeStrings['property.' + self.property.id + '.footnote'] = self.toLocaleDictionary('properties',self.property.id,'footnote',self.property.footnote);
    }

    toLocaleDictionary(table ,id, column_name, original_value){
        const self = this;

        if(!table || !id || !column_name)
            return original_value;

        if(self.$cookies.get('lang') == 'en')
            return original_value;

        if(self.language){
            let result = self.language.filter(function (row) {

                return table+'.'+id+'.'+column_name+'.'+self.$cookies.get('lang') == row.key;
            });
            if(result.length && result[0].value != '')
                return result[0].value ;
            else
                return original_value;
        }
    }

    changeArrayToStringForLocalization(objectArray,parameter){
        const self = this;
        let finalString = '';
        if(parameter){
            objectArray.forEach(function (val) {
                finalString += val[parameter];
            });
            return finalString;
        }
        else
            return objectArray.join('\n');
    }

    checkUnitTypesVisibility() {
        const self = this;

        self.property = self.data.property;
        self.initLocaleString();
        let unit_types_visibility = self.SSAppService.getPropertySetting(self.property, 'unit_statistics');

        if (unit_types_visibility !== null && typeof unit_types_visibility === 'object' && unit_types_visibility.hasOwnProperty("value") && unit_types_visibility.value != null) {
            unit_types_visibility = unit_types_visibility.value;
        }

        if (unit_types_visibility == SHOWSUITE.AVAILABILITY_BROKERS_TEAM) {
            if (!self.SSUserService.isBroker()) {
                self.disableUnitType = true;
                return;
            }
            else {
                loading(true);
                self.SSUserService.getBrokerAgencyWithProperty(self.property).then(function (response) {
                    loading(false);
                    if (response instanceof Error) {
                        self.SSAlertService.parseAndDisplayError(response);
                        return;
                    }
                    if (!(response.data && response.data.properties)) {
                        self.disableUnitType = true;
                        return;
                    }

                    let properties = response.data.properties;

                    $.each(properties, function (index, _property) {
                        if (_property.id === self.property.id) {
                            self.disableUnitType = false;
                            return;
                        }
                    });
                });
            }
        } else if (unit_types_visibility == SHOWSUITE.AVAILABILITY_BROKERS_ALL) {
            if (self.SSUserService.isBroker()) {

            } else {
                self.disableUnitType = true;
                return;
            }
        } else if (unit_types_visibility == SHOWSUITE.AVAILABILITY_PUBLIC) {

        } else {
            self.disableUnitType = true;
        }
    }

    getPropertyContactNumber() {
        const self = this;

        let url = "properties/" + self.property.id + "/contact-details";
    
        loading(true);
        self.SSHttpService.getAPIRequest(url).then(function (response) {
            loading(false);
            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            self.property.contact_info = response.data;
            
            if (!self.property.contact_info.hasOwnProperty("photo") || !self.property.contact_info.photo) {
                self.property.contact_info.photo = self.property.developer.logo_file;
            }

            if (self.property.contact_info && !self.property.contact_info.phone1) {
                self.property.contact_info.phone1 = self.property.sales_contact_number ? self.property.sales_contact_number : self.property.contact_number;
            }
            
            $('#call-modal').modal('show');

            self.hasCalledPropertyContactAPI = true;
        });
    }

    getPropertyDetails() {
        const self = this;

        self.SSAppService.isPageLoaded = true;

        self.property = self.data.property;
        self.initLocaleString();
        self.property.available_balance = self.SSAppService.getRemainingUnits(self.property);

        self.property.processed_facilities = self.processFacilities(self.localeStrings['property.' + self.property.id + '.facilities']);
        self.property.processed_clubhouse_facilities = self.processFacilities(self.localeStrings['property.' + self.property.id + '.clubhouse_facilities']);
        
        self.splitPropertyAssets();
        self.setPropertiesOnMap();
        let propertyImgg = self.SSAppService.getPropertyHeroThumbUrl(self.property);

        angular.element("head").append('<meta property="og:description" content="'+self.property.name+'" />');
        angular.element("head").append('<meta name="description" content="'+self.property.name+'" />');
        angular.element("head").append('<meta property="og:title" content="'+self.property.name+'" />');
        angular.element("head").append('<meta property="og:image" content="'+propertyImgg+'" />');

        // Drone Views, set sitemap image
        self.setSelectedDroneImage(self.findDroneImage(null, null),true);

        self.isEoiAccessible = !!self.SSUserService.getActivePropertyLaunchForEoiCreate(self.property, 'EOI');

        // self.getPropertyContactNumber();

        //Prepare 306 photos
        if (self.property.property_assets) {
            self.property.assets360Imgs = self.property.property_assets.filter(x => x.type === self.PROPERTY_ASSET_TYPE_360_IMAGE);
            let assetsAirViewsImgs = self.property.property_assets.filter(x => x.type === self.PROPERTY_ASSET_TYPE_AIR_VIEW_IMAGE);
            let assetsAirViews3D = self.property.property_assets.filter(x => x.type === self.PROPERTY_ASSET_TYPE_AIR_VIEW_3D);

            if (assetsAirViews3D && assetsAirViews3D.length > 0) {
                self.property.assetsAirViewsImgs = assetsAirViews3D;
                self.air_view_display = "Air Views 3D";
                self.air_view_3d = true;
            } else {
                self.property.assetsAirViewsImgs = assetsAirViewsImgs;
                self.air_view_display = "Air Views";
                self.air_view_3d = false;
            }

            self.property.assetsShowGallery360 = self.property.property_assets.filter(x => x.type === self.PROPERTY_ASSET_TYPE_SHOW_GALLERY_360);
            self.property.assetsArchitectural360 = self.property.property_assets.filter(x => x.type === self.PROPERTY_ASSET_TYPE_ARCHITECTURAL_360);
        }
    }

    setPropertiesOnMap() {
        const self = this;

        if (self.map != null)
            return;

        self.NgMap.getMap().then(function (map) {

            self.map = map;

            let infowindow = new google.maps.InfoWindow();

            let marker, i;

            i = 0;

                //let propertyLink = self.SSAppService.viewProperty(self.properties[i].named_id);
                let propertyImg = self.SSAppService.getPropertyHeroThumbUrl(self.property);
                let propertyRegion = self.SSAppService.getRegionName(self.property.country, self.property.region);
                let propertyTenure = self.property.tenure;
                let propertyRemainingUnits = self.SSAppService.getRemainingUnits(self.property);
                let propertyTotalUnits = self.SSAppService.getTotalUnits(self.property);

                let infoWindowContent = '' +
                    '<a target="_blank" href="/' + self.property.named_id + '">' +
                    '<table><tr><td><figure class="pull-left" style="width:175px;margin-right:10px"><img src="' + propertyImg + '"></figure></td><td>' +
                    '<aside class="regular-font project-details pull-left">' +
                    '<span class="bold-font">' + self.property.name + '</span>' +
                    '<p>' + propertyRegion + '<br>Tenure: ' + propertyTenure + '<br>' +
                    'Units Balance : ' + propertyRemainingUnits + ' / ' + propertyTotalUnits + '</p>' +
                    '</aside></td></tr></table>' +
                    '</a>';

                //let infoWindowContent = '<a target="_blank" href="/' + self.properties[i].id + '"><div>' + self.properties[i].name + '</div><div>' + self.properties[i].location + '</div></a>';

                marker = new google.maps.Marker({
                    position: {lat: self.property.lat, lng: self.property.long},
                    animation: google.maps.Animation.DROP,
                    label: self.property.name[0],
                    map: self.map
                });

                google.maps.event.addListener(marker, 'click', (function (marker, i) {
                    return function () {
                        infowindow.setContent(infoWindowContent);
                        infowindow.open(self.map, marker);
                    }
                })(marker, i));

        });
    }

    toggle3dView() {
        const self = this;
        self.hide3dPlaceholder = true;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJ_DT_1D_ProjectModel_Clicks', {'event_category': 'Project Details', 'property': self.property.name});

        if (self.viewMedia !== '3d') {
            self.SSDisclaimerService.getAccepted(
                'PROPERTY_' + self.property.id + '_3D_MODEL',
                self.SSDisclaimerService.MESSAGE_PROPERTY3D_VIEW,
                self.SSDisclaimerService.STORAGE_LOCAL,
                function () {
                    self.show3dView();
                }
            );
        } else {
            self.viewMedia = 'hero';
        }
    }

    trackGAShowGallery360Imgs() {
        const self = this;

        console.log("Show Gallery 360 events!!!");
    }

    trackGA360Event() {
        const self = this;

        if (!self.trackGA360FirstTime) {
            //Add to Firebase Analytics Event
            firebase.analytics().logEvent('PJ_DT_1D_360VirtualTour_Clicks', {'event_category': 'Project Details', 'property': self.property.name});
            self.trackGA360FirstTime = true;
        }
    }

    trackGAArchitectural360Imgs() {
        const self = this;

        console.log("Architectural 360 events!!!");
    }

    trackGAAirViewImgs() {
        const self = this;

        if (!self.trackGAAirViewFirstTime) {
            //Add to Firebase Analytics Event
            firebase.analytics().logEvent('PJ_DT_1D_Airview360l_Clicks', {'event_category': 'Project Details', 'property': self.property.name});
            self.trackGAAirViewFirstTime = true;
        }
    }

    getFromLocalStorage(key) {
        const self = this;
        return self.$storage[key];
    }
    // Remove Disclaimer and add it on property detail page (in footer)
    // projectView(propertyId) {
    //     const self = this;
    //     let projectDisclmr = false;
    //     let disclaimers = self.getFromLocalStorage('DISCLAIMERS');
    //     if(typeof dislaimers !="undefined"){
    //         for (let i = 0; i < disclaimers.length; i++) {
    //             console.log(disclaimers[i]);
    //             if(disclaimers[i]=='PROPERTY_' + propertyId +'_MODEL'){
    //                 projectDisclmr = true;
    //             }
    //         }
    //     }
    //     //console.log('I am Dsclmr'+projectDisclmr);
    //     if(!projectDisclmr) {
    //         self.SSDisclaimerService.getAccepted(
    //             'PROPERTY_' + propertyId + '_MODEL',
    //             self.SSDisclaimerService.MESSAGE_PROPERTY_VIEW,
    //             self.SSDisclaimerService.STORAGE_LOCAL,
    //             function () {
    //                 self.getPropertyDetails();
    //                 self.trackPropertyViews();
    //                 self.getPropertyUnitsStats();
    //                 // Detecting fullscreen changex
    //                 self.$rootScope.$on('FBFullscreen.change', function ($event, isEnabled) {
    //                     self.$timeout(function () {
    //                         self.isFullscreen = isEnabled;
    //                     });
    //                 });
    //             }
    //         );
    //         $("#disclaimerModal").on('hide.bs.modal', function() {
    //             if (!self.SSDisclaimerService.disclaimerAccepted)
    //             {
    //                 self.$window.location = '/';
    //             }
    //         });

    //     }

    // }

    show3dView() {
        const self = this;

        if (!self.model3dLoaded) {
            self.model3dLoaded = true;
            self.viewMedia = 'model3dLoading';
            self.loadingText = 'Loading 3D Model ...'
            self.$timeout(function () {
                self.viewMedia = '3d';
            }, 9000);
        } else {
            self.viewMedia = '3d'
        }

        var url = 'track/view/trackPropertyUnitThreeDView/' + self.property.id;
        var params = {
            property_id: self.property.id,
            property_type: "property"
        };

        self.SSHttpService.postAPIRequest(url, params).then(function (responseObj) {
            if (responseObj instanceof Error) {
                console.log(responseObj.message + "\n" + JSON.stringify(responseObj.data));
                return;
            }
        });
    }

    toggleDownloadsModal(status) {
        const self = this;

        self.downloadsModal = status;
        $('#downloadsModal').modal(status ? 'show' : 'hide');
    }

    getPropertyUnitsStats() {
        const self = this;

        self.unitsStats = self.data.unit_stats;

        if (self.unitsStats) {

            let totalUnits = 0;
            let totalMinPsf = 0;
            let totalMaxPsf = 0;

            _.forEach(self.unitsStats, function (unitsStat) {
                totalUnits += unitsStat.total_units;
                totalMinPsf += unitsStat.min_psf * unitsStat.total_units;
                totalMaxPsf += unitsStat.max_psf * unitsStat.total_units;
            });

            if (totalUnits > 0) {
                self.propertyMediaPsf = Math.round((totalMinPsf + totalMaxPsf) / (totalUnits * 2));
            }
        }
    }

    splitPropertyAssets() {
        const self = this;

        self.assets = {
            hero: [],
            video: [],
            siteplan: [],
            facilities: [],
            brochurePdfs: [],
            floorPlanPdfs: [],
            map: []
        };

        _.each(self.property.property_assets, function (asset) {
            if (asset.type == self.ASSET_TYPE.HERO_IMAGE || asset.type == self.ASSET_TYPE.KEY_IMAGE) {
                self.assets.hero.push(asset);
            } else if (asset.type == self.ASSET_TYPE.AD_VIDEO || asset.type == self.ASSET_TYPE.LAYOUT_VIDEO) {
                self.assets.video.push(asset);
            } else if (asset.type == self.ASSET_TYPE.SITE_PLAN_A_IMAGE || asset.type == self.ASSET_TYPE.SITE_PLAN_B_IMAGE) {
                self.assets.siteplan.push(asset);
            } else if (asset.type == self.ASSET_TYPE.FACILITY_MAGE) {
                self.assets.facilities.push(asset);
            } else if (asset.type == self.ASSET_TYPE.BROCHURE_PDF) {
                self.assets.brochurePdfs.push(asset);
            } else if (asset.type == self.ASSET_TYPE.FLOOR_PLAN_PDF) {
                self.assets.floorPlanPdfs.push(asset);
            } else if (asset.type == self.ASSET_TYPE.MAP_IMAGE) {
                self.assets.map.push(asset);
            }
        });

        if (self.assets.video && self.assets.video.length > 0) {
            self.viewMedia = "video";
        } else {
            self.viewMedia = "hero";
        }
    }

    trackPropertyViews() {
        const self = this;

        let url = 'track/view/properties/' + self.property.id;
        let params = {
            property_id: self.property.id
        };

        self.SSHttpService.postAPIRequest(url, params).then(
            function (responseObj) {
                if (responseObj instanceof Error) {
                    console.log(responseObj.message + "\n" + JSON.stringify(responseObj.data));
                    return;
                }
            }
        );
    }

    scrollTo(id) {
        jQuery('html,body').animate({
            scrollTop: jQuery(id).offset().top
        }, 'slow');
    }

    scrollToLink() {
        $('.in-page-nav a[href^="#"], .project-view-info a[href^="#"]').on("click", function(e) {
            e.preventDefault();
            var $a = $(this),
            href = $a.attr("href");
            var $target = $(href);
            if(!$target.length) { return; }
            $("html, body").animate({
            scrollTop : $target.offset().top
            });
        });
    }
    

    maskPhoneNumber(context, contextPrefix, formId) {
        let self = this;

        let parent = jQuery;
        if (formId) {
            parent = jQuery('#' + formId);
        }

        jQuery.each(parent.find('input[type=tel]'), function (i, el) {
            let field = jQuery(el);

            let allowDropdown = field.attr('allowDropdown');
            allowDropdown = !allowDropdown || allowDropdown !== 'false';

            field.intlTelInput({
                initialCountry: 'sg',
                geoIpLookup: self.SSUtilService.geoIpLookup,
                allowDropdown: allowDropdown,
                preferredCountries: ['sg']
            });

            let model = field.attr('ng-model');
            if (model) {
                if (startsWith(model, contextPrefix)) {
                    model = model.substring(contextPrefix.length);
                }
                let value = self.$parse(model)(context);
                if (value) {
                    field.intlTelInput('setNumber', value);
                }
            }


            field.on("keyup change", function () {
                field.parent().removeClass("has-error");
            });
            field.blur(function () {
                var currentFormat = (field.val() && field.val()[0] === "+") ? intlTelInputUtils.numberFormat.INTERNATIONAL : intlTelInputUtils.numberFormat.NATIONAL;
                field.val(field.intlTelInput("getNumber", currentFormat));

                field.parent().removeClass("has-error");
                if (!field.intlTelInput("isValidNumber")) {
                    field.parent().addClass("has-error");
                    if (model) {
                        //self.$parse(model).assign(context, ""); // Don't remove the number if invalid, unless we upgrade to version of latest intl-tel-input
                    }
                } else {
                    if (model) {
                        self.$parse(model).assign(context, field.intlTelInput("getNumber"));
                    }
                }
            });
        });
    }

    animateRadialProgressValue(id, value) {
        const self = this;

        animateRadialProgressValue(id, value);
    }

    // Fullscreen
    showFullscreen($event) {
        const self = this;
        
        if (!self.Fullscreen.isEnabled()) {
            self.toggleFullscreen($event);
        }
    }

    closeFullscreen() {
        const self = this;

        if (self.Fullscreen.isEnabled()) {
            self.toggleFullscreen();
        }
    }

    toggleFullscreen($event) {
        const self = this;

        if (self.Fullscreen.isEnabled()) {
            self.Fullscreen.cancel();
        } else {
            if (!$event) return;

            if (typeof $event == "string") {
                self.Fullscreen.enable(document.getElementById($event));
            } else if ($event.currentTarget.tagName === "BUTTON") {
                self.Fullscreen.enable($event.currentTarget.parentNode);
            } else {
                self.Fullscreen.enable($event.currentTarget);
            }
        }
    }
    // Fullscreen

    // Drone Views
    findDroneImage(camera, level) {
        const self = this;

        let droneImage = null;

        if(self.property.property_drone_image) {
            jQuery.each(self.property.property_drone_image, function (i, _droneImage) {
                // Match Camera
                if ((camera && _droneImage.camera != camera) || (!camera && _droneImage.camera)) {
                    return;
                }
                // Match Level
                if ((level && _droneImage.level != level) || (!level && _droneImage.level)) {
                    return;
                }
                // Match found
                droneImage = _droneImage;
                return false;
            });
        }

        // Ignore Image with no URI
        if (droneImage && !droneImage.file_uri) {
            droneImage = null;
        }

        return droneImage;
    }

    toggleMenu($event) {
        // Code copied (modified version) from UI scripts to work with angular.
        let element = jQuery($event.currentTarget);
        if (element.parent().has("ul")) {
            $event.preventDefault();
        }
        if (!element.hasClass("open")) {
            // hide any open menus and remove all other classes
            jQuery(".accordion li ul").slideUp(350);
            jQuery(".accordion li a").removeClass("open");

            // open our new menu and add the open class
            element.next("ul").slideDown(350);
            element.addClass("open");
        } else if (element.hasClass("open")) {
            element.removeClass("open");
            element.next("ul").slideUp(350);
        }
    }

    toggleDroneCamera($event, droneImage, fullscreen=false) {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJ_DT_1D_StoreyView_Clicks', {'event_category': 'Project Details', 'property': self.property.name});

        self.SSDisclaimerService.getAccepted(
            'PROPERTY_' + self.property.id + '_DRONE_IMAGE',
            self.SSDisclaimerService.MESSAGE_DRONE_IMAGE_VIEW,
            self.SSDisclaimerService.STORAGE_LOCAL,
            function () {
                if (fullscreen) {
                    self.setSelectedDroneImage(droneImage);
                    return;
                }
                // Toggle Menu
                self.toggleMenu($event);

                if (!jQuery($event.currentTarget).hasClass('open')) {
                    // If no camera button selected
                    self.setSelectedDroneImage(self.findDroneImage(null, null));
                } else {
                    self.setSelectedDroneImage(droneImage);
                }
            }
        );
    }

    setSelectedDroneImage(droneImage, firstLoad=false) {
        const self = this;
        if (droneImage && droneImage.file_uri) {
            self.selectedDroneImage = droneImage;
            if(!firstLoad)
                self.trackDroneViews(droneImage);
        } else {
            //'/images/dronewhite.png';
            self.selectedDroneImage = null;
        }
    }
    // Drone Views
    trackDroneViews(droneImage){
        const self = this;
        if (!droneImage)
            return;
        let url = 'properties/droneimages/track/'+droneImage.id;
        let params = {
            data: droneImage
        };
        self.SSHttpService.postAPIRequest(url, params).then(
            function (responseObj) {
                if (responseObj instanceof Error) {
                    console.log(responseObj.message + "\n" + JSON.stringify(responseObj.data));
                    return;
                }
            }
        );
    }

    processFacilities(facilities) {
        const self = this;

        facilities = facilities ? facilities.split("\n") : [];

        let processed_facilities = [];
        if (facilities.length > 0) {
            $.each(facilities, function(index, facility) {
                let splited_strings = facility.split("||");
                if (splited_strings.length > 1) {
                    let header_element = splited_strings[0].trim(); //Remove empty space for begining and end
                    let body_element = splited_strings[1].trim(); //Remove empty space for begining and end

                    let selected_level = processed_facilities.find(x => x.level === header_element);

                    if (selected_level) {
                        selected_level.facilities.push(body_element);
                    } else {
                        //Add into the array
                        let new_element = {
                            "level": header_element,
                            "defined_level": true,
                            "facilities": [body_element]
                        };
                        processed_facilities.push(new_element);
                    }
                } else if (splited_strings.length === 1) {
                    let body_element = splited_strings[0].trim();

                    let selected_level = processed_facilities.find(x => x.defined_level === false);

                    if (selected_level) {
                        selected_level.facilities.push(body_element);
                    } else {
                        //Add into the array
                        let new_element = {
                            "level": "Unknown",
                            "defined_level": false,
                            "facilities": [body_element]
                        };
                        processed_facilities.push(new_element);
                    }
                }
                
            });

            //Sort the undefined level to the most bottom
            processed_facilities = processed_facilities.sort(function(a,b){
                return (a.defined_level === b.defined_level)? 0 : a.defined_level? -1 : 1;
            });

            $.each(processed_facilities, function(index, processed_facility) {
                processed_facility.facilities = _.uniq(processed_facility.facilities);
            });
        }
        return processed_facilities;
    }

    getPropertySetting() {
        const self = this;

        self.display_statistics = false;

        let sale_statistics_visibility = self.SSAppService.getPropertySetting(self.property, 'sale_statistics_visibility');

        if (sale_statistics_visibility !== null && typeof sale_statistics_visibility === 'object' && sale_statistics_visibility.hasOwnProperty("value") && sale_statistics_visibility.value != null) {
            sale_statistics_visibility = sale_statistics_visibility.value;
        }

        if (sale_statistics_visibility == SHOWSUITE.AVAILABILITY_BROKERS_TEAM || sale_statistics_visibility == SHOWSUITE.AVAILABILITY_TEAM_LEADS) {
            if (self.SSUserService.isBroker()) {
                loading(true);
                self.SSUserService.getBrokerAgencyWithProperty(self.property).then(function (response) {
                    loading(false);
                    if (response instanceof Error) {
                        self.SSAlertService.parseAndDisplayError(response);
                        return;
                    }
                    if (!(response.data && response.data.properties)) {
                        self.display_statistics = false;
                        return;
                    }

                    let properties = response.data.properties;

                    $.each(properties, function (index, _property) {
                        if (_property.id === self.property.id) {
                            self.display_statistics = true;
                            return;
                        }
                    });
                });
            }
        } else if (sale_statistics_visibility == SHOWSUITE.AVAILABILITY_BROKERS_ALL) {
            if (self.SSUserService.isBroker()) {
                self.display_statistics = true;
            }
        } else if (sale_statistics_visibility == SHOWSUITE.AVAILABILITY_PUBLIC) {
            self.display_statistics = true;
        }
    }

    openCallPopUpModal() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_CALL_CLICKS', {'event_category': 'Project Details', 'property': self.property.name});        

        if (self.hasCalledPropertyContactAPI) {
            $('#call-modal').modal('show');
        } else {
            self.getPropertyContactNumber();
        }
    }

    checkCallButtonActive() {
        const self = this;

        if (self.property) {
            if (self.property.sales_contact_number || self.property.contact_number || self.data.share_code) {
                self.callButtonActive = true;
            }
        }
    }

    prepareLoadShareCode() {
        const self = this;

        let url = "properties/" + self.property.id + "/track-share-link-back";
        let params = {
            share_code: self.data.share_code
        }
    
        loading(true);
        self.SSHttpService.getAPIRequest(url, params).then(function (response) {
            loading(false);
            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }
        });
    }

    trackGetDirectionAnalytics() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_DIRECTION_CLICKS', {'event_category': 'Project Details', 'property': self.property.name});
    }

    viewUnits(msg = false) {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_VWUNIT_CLICKS', {'event_category': 'Project Details', 'property': self.property.name});

        self.SSAppService.viewUnits(self.property.named_id, self.property.product_type, msg);
    }

    showEnquireModal() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_ENQUIRE_CLICKS', {'event_category': 'Project Details', 'property': self.property.name});

        self.SSUserService.showEnquireModal(self.property, null);
    }

    trackVideoAnalytics() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_VIDEO_CLICKS', {'event_category': 'Project Details', 'property': self.property.name});
    }

    trackImageAnalytics() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_IMAGES_CLICKS', {'event_category': 'Project Details', 'property': self.property.name});
    }

    track3DModelAnalytics() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_3DMODEL_CLICKS', {'event_category': 'Project Details', 'property': self.property.name});
    }

    trackAirViewAnalytics() {
        const self = this;

        //Add to Firebase Analytics Event
        if (self.air_view_3d) {
            firebase.analytics().logEvent('PJT_DT_3DAIRVWS_CLICKS', {'event_category': 'Project Details', 'property': self.property.name});
        } else {
            firebase.analytics().logEvent('PJT_DT_AIRVWS_CLICKS', {'event_category': 'Project Details', 'property': self.property.name});    
        }
    }

    trackStoreyViewAnalytics() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_STOREYVW_CLICKS', {'event_category': 'Project Details', 'property': self.property.name});    
    }

    trackSiteMapAnalytics() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_SITEMAP_CLICKS', {'event_category': 'Project Details', 'property': self.property.name});    
    }

    trackFacilitiesAnalytics() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_FACILITIES_CLICKS', {'event_category': 'Project Details', 'property': self.property.name});    
    }

    trackUnitTypesAnalytics() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_UNITTYP_CLICKS', {'event_category': 'Project Details', 'property': self.property.name});    
    }

    trackLocationMapAnalytics() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_LOCMAP_CLICKS', {'event_category': 'Project Details', 'property': self.property.name});    
    }

    trackDeveloperAnalytics() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_DEV_CLICKS', {'event_category': 'Project Details', 'property': self.property.name});    
    }

    trackTransportationAnalytics() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_TRANSPORT_CLICKS', {'event_category': 'Project Details', 'property': self.property.name});    
    }

    trackShowGallery360Analytics() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_SHWGALLERY360_ClICKS', {'event_category': 'Project Details', 'property': self.property.name});    
    }

    trackArchitectural360Analytics() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_ARC360_CLICKS', {'event_category': 'Project Details', 'property': self.property.name});    
    }

    track360PhotoAnalytics() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_360PHOTO_CLICKS', {'event_category': 'Project Details', 'property': self.property.name});    
    }

    showVideoShareModal() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_VIDEO_SHARE', {'event_category': 'Project Details', 'property': self.property.name});    

        self.SSAppService.showShareModal(self.property, null, 'video');
    }

    show3DModelShareModal() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_3DMODEL_SHARE', {'event_category': 'Project Details', 'property': self.property.name});    

        self.SSAppService.showShareModal(self.property, null, '3dmodel');
    }

    showAirViewShareModal() {
        const self = this;

        //Add to Firebase Analytics Event
        if (self.air_view_3d) {
            firebase.analytics().logEvent('PJT_DT_3DAIRVIEW_SHARE', {'event_category': 'Project Details', 'property': self.property.name});
        } else {
            firebase.analytics().logEvent('PJT_DT_AIRVIEW_SHARE', {'event_category': 'Project Details', 'property': self.property.name});
        }
        

        self.SSAppService.showShareModal(self.property, null, 'airview');
    }

    showStoreyViewShareModal() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_STOREYVW_SHARE', {'event_category': 'Project Details', 'property': self.property.name});    

        self.SSAppService.showShareModal(self.property, null, 'storeyview');
    }

    show360PhotoShareModal() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_360PHOTO_SHARE', {'event_category': 'Project Details', 'property': self.property.name});    

        self.SSAppService.showShareModal(self.property, null, '360');
    }

    showShowGallery360ShareModal() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_SHWGALLERY360_SHARE', {'event_category': 'Project Details', 'property': self.property.name});    

        self.SSAppService.showShareModal(self.property, null, 'showGallery360');
    }

    showArchitectural360ShareModal() {
        const self = this;

        //Add to Firebase Analytics Event
        firebase.analytics().logEvent('PJT_DT_ARC360_SHARE', {'event_category': 'Project Details', 'property': self.property.name});    

        self.SSAppService.showShareModal(self.property, null, 'architectural360');
    }

    getUnitData() {
        const self = this;

        self.commercialSoldUnits = 0;
        self.commercialUnSoldUnits = 0;
        self.residentialSoldUnits = 0;
        self.residentialUnSoldUnits = 0;
        self.totalresidentialUnits = 0;
        self.totalcommercialUnits = 0;

        self.TOTAL_PROPERTY_UNITS = 0;

        jQuery.each(self.unitsData, function (key, value) {

            self.TOTAL_PROPERTY_UNITS += value.count; // Total Project Units

            if (value.status == self.UNIT_STATUS_DRAFT) {
                return; // Skip and continue.
                // DRAFT units are not counted under Launched Units, so will not be counted for Sold/Unsold
            }

            if (value.category == 7) {
                if (value.status == self.UNIT_STATUS_SOLD) {
                    self.commercialSoldUnits += value.count;
                } else {
                    self.commercialUnSoldUnits += value.count;
                }
            } else {
                if (value.status == self.UNIT_STATUS_SOLD) {
                    self.residentialSoldUnits += value.count;
                } else {
                    self.residentialUnSoldUnits += value.count;
                }
            }
        });

        self.totalresidentialUnits = self.residentialSoldUnits + self.residentialUnSoldUnits;
        self.totalcommercialUnits = self.commercialSoldUnits + self.commercialUnSoldUnits;
    }
}

CustomerPropertyDetailsController.$inject = ['$scope', '$rootScope', '$localStorage', '$timeout', '$window', '$cookies','NgMap', 'SSHttpService', 'SSUtilService', 'SSAppService', 'SSUserService', 'Fullscreen', 'SSDisclaimerService'];
app.controller('CustomerPropertyDetailsController', CustomerPropertyDetailsController);
