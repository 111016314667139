"use strict";

class CustomerSearchResultsController {
  constructor($scope, $location, $anchorScroll, $localStorage, SSHttpService, SSUtilService, SSSearchService, SSAppService, SSUserService, SSConfigService) {

    const self = this;

    self.$scope = $scope;
    self.$location = $location;
    self.$anchorScroll = $anchorScroll;
    self.$storage = $localStorage;
    self.SSHttpService = SSHttpService;
    self.SSUtilService = SSUtilService;
    self.SSSearchService = SSSearchService;
    self.SSAppService = SSAppService;
    self.SSUserService = SSUserService;
    self.SSConfigService = SSConfigService;

    self.sDb = self.SSSearchService.searchProperties;

    self.SSSearchService.userIsOnSearchResult = true;

    //search params
    self.sDb.apiSqType = SHOWSUITE.apiSqType;
    self.sDb.apiPropertyId = SHOWSUITE.apiPropertyId;
    self.sDb.apiKeyword = SHOWSUITE.apiKeyword;
    self.sDb.apiMinPrice = SHOWSUITE.apiMinPrice;
    self.sDb.apiMaxPrice = SHOWSUITE.apiMaxPrice;
    self.sDb.apiMinBedroom = SHOWSUITE.apiMinBedroom;
    self.sDb.apiMaxBedroom = SHOWSUITE.apiMaxBedroom;
    self.sDb.apiMinSize = SHOWSUITE.apiMinSize;
    self.sDb.apiMaxSize = SHOWSUITE.apiMaxSize;
    self.sDb.apiCountry = "702";//SHOWSUITE.apiCountry;
    //self.selectedCountry = self.SSAppService.appServiceObject.selectedCountry;
    self.selectedCountry = self.SSConfigService.getCountry(self.sDb.apiCountry);
    self.sDb.districts = SHOWSUITE.apiDistricts ? SHOWSUITE.apiDistricts.split(',') : [];
    self.sDb.apiMinBathroom = SHOWSUITE.apiMinBathroom;
    self.sDb.apiMaxBathroom = SHOWSUITE.apiMaxBathroom;
    self.sDb.apiLat = SHOWSUITE.apiLat;
    self.sDb.apiLong = SHOWSUITE.apiLong;
    self.sDb.apiMinPsf = SHOWSUITE.apiMinPsf;
    self.sDb.apiMaxPsf = SHOWSUITE.apiMaxPsf;
    self.sDb.apiPropertyType = SHOWSUITE.apiPropertyType;
    self.sDb.apiUnitType = SHOWSUITE.apiUnitType;
    self.sDb.apiTenure = SHOWSUITE.apiTenure;
    self.sDb.apiPropertyOffset = SHOWSUITE.apiPropertyOffset;
    self.sDb.apiPropertyLimit = SHOWSUITE.apiPropertyLimit;
    self.sDb.apiUnitOffset = SHOWSUITE.apiUnitOffset;
    self.sDb.apiUnitLimit = SHOWSUITE.apiUnitLimit;

//    if (!self.sDb.showAdvanceSearch) {
//      self.sDb.showAdvanceSearch = self.sDb.apiKeyword || self.sDb.apiTenure || self.sDb.apiPropertyType ||
//        self.sDb.apiMinPsf || self.sDb.apiMaxPsf || self.sDb.apiMinSize || self.sDb.apiMaxSize;
//    }

    self.selectedProperty = 'TODO';

    self.init();

  }

  init() {
    const self = this;

    // Start: Districts
    self.districts = _.clone(self.selectedCountry.regions);

    self.districtsRegions = [
      {
          name: 'Core Central Region',
          checked: false,
          districts: []
      },
      {
          name: 'Rest of Central Region',
          checked: false,
          districts: []
      },
      {
          name: 'Outside Central Region',
          checked: false,
          districts: []
      }
    ];

    _.forEach(self.districts, function (district) {

      //get checked status from local cache
      district.checked = _.includes(self.sDb.districts, district.code);

      let regionGroup = 2;
      //group the regions
      if (_.includes(['D01', 'D04', 'D06', 'D09', 'D10', 'D11'], district.code)) {
          regionGroup = 0;
      } else if (_.includes(['D02', 'D03', 'D07', 'D08', 'D12', 'D13', 'D14', 'D15'], district.code)) {
          regionGroup = 1;
      }
      self.districtsRegions[regionGroup].districts.push(district);

    });
    self.districtsRegions[0].checked = self.isDistrictsRegionChecked(0);
    self.districtsRegions[1].checked = self.isDistrictsRegionChecked(1);
    self.districtsRegions[2].checked = self.isDistrictsRegionChecked(2);
    self.districts = null;
    // End: Districts

    self.propertyBoxClick = function (property) {
      self.sDb.apiPropertyId = property.id;
      self.sDb.apiSqType = self.SSSearchService.SQ_TYPE.UNITS;
      self.sDb.apiUnitOffset = self.SSSearchService.DEFAULT_OFFSET;
      self.sDb.apiUnitLimit = self.SSSearchService.DEFAULT_LIMIT;

      self.getSearchResults();
    };

    self.unitViewClick = function (unit) {
      self.SSAppService.viewUnit(self.sDb.apiPropertyId, unit.named_id);
    };

    //search watcher
    self.$scope.$watch(()=>self.SSSearchService.searchTriggered, function (searchTriggered) {
      if (searchTriggered) {

        self.getSearchResults();

        //so it can be triggered again
        self.SSSearchService.searchTriggered = false;
      }
    });

    self.SSAppService.isPageLoaded = true;

    self.getSearchResults();

    self.$scope.$watch(()=>self.sDb.showAdvanceSearch, function (showAdvanceSearch) {
      if ( !self.advanceSearchData) {
        self.getAdvanceSearchData();
      }
    });
  }

  getAdvanceSearchData() {
    const self = this;

    let url = 'advance-search-data';

    self.SSHttpService.getAPIRequest(url).then(
      function (responseObj) {

        if (responseObj instanceof Error) {
          return;
        }

        self.advanceSearchData = responseObj.data;

        /**
         * Mrt stations grouping.
         */

        _.forEach(self.advanceSearchData.mrt_stations, function (mrt_station) {
          mrt_station.checked = _.includes(self.sDb.mrt_stations, mrt_station.id);
        });

        self.mrtLines = [];

        let mrtLines = self.SSUtilService.groupBy(self.advanceSearchData.mrt_stations, 'line_code');
        _.forEach(mrtLines, function (mrtLine) {
          self.mrtLines.push({
            line_code: mrtLine[0].line_code,
            line_name: mrtLine[0].line_name,
            stations: mrtLine,
            checked: false
          });
        });

        _.forEach(mrtLines, function (mrtLine, index) {
          self.mrtLines[index].checked = self.isMrtLineChecked(index);
        });

        self.advanceSearchData.mrt_stations = null;


        /**
         * Schools grouping.
         */

        _.forEach(self.advanceSearchData.schools, function (school) {
          school.checked = _.includes(self.sDb.schools, school.id);
        });

        self.schoolTypes = [];

        let schoolTypes = self.SSUtilService.groupBy(self.advanceSearchData.schools, 'type');
        _.forEach(schoolTypes, function (schoolType) {
          self.schoolTypes.push({
            type: !schoolType[0].type ? 'Others' : schoolType[0].type,
            schools: schoolType,
            checked: false
          });
        });

        _.forEach(schoolTypes, function (schoolType, index) {
          self.schoolTypes[index].checked = self.isSchoolTypeChecked(index);
        });

        self.advanceSearchData.schools = null;


        /**
         * Developers.
         */

        _.forEach(self.advanceSearchData.developers, function (developer) {
          developer.checked = _.includes(self.sDb.developers, developer.id);
        });
      });
  }

  getSearchResults() {
      const self = this;

      if (self.fetchingUnitsFor) {
          return;
      }

      let url = self.SSSearchService.getSearchUrlForApi();
      let browserUrl = url;//self.SSSearchService.getSearchUrlWithParams();
      // self.SSUtilService.updateWindowUrl(browserUrl);

      if (self.SSSearchService.isSearchingForUnits()) {
          if (self.unitsCount == null) {
              url += '&send_total_count=true';
          }
          self.fetchingUnitsFor = self.sDb.apiPropertyId;

          self.loadingUnits = true;
      } else if (self.SSSearchService.isSearchingForProperties()) {
          if (self.propertiesCount == null) {
              url += '&send_total_count=true';
          }
      }

      self.userMessage = 'FETCHING SEARCH RESULTS';

      loading(true);
      self.SSHttpService.getAPIRequest(url).then(function (responseObj) {
          loading(false);

          if (responseObj instanceof Error) {
              self.userMessage = 'Unable to fetch search results';
              self.fetchingUnitsFor = null;
              return;
          }

          if (_.has(responseObj.data, 'properties')) {
              self.userMessage = 'YOUR SEARCH RESULTS';
              self.properties = responseObj.data.properties;
              if (_.has(responseObj.data, 'count')) {
                  self.propertiesCount = responseObj.data.count;
                  if (responseObj.data.count <= 0) {
                      self.userMessage = 'NO SEARCH RESULTS FOUND';
                  }
                  self.propertiesPages = Math.ceil(self.propertiesCount / self.SSSearchService.DEFAULT_LIMIT);
              }
          }

          if (_.has(responseObj.data, 'units')) {
              self.userMessage = 'YOUR SEARCH RESULTS';
              self.units = responseObj.data.units;
              self.fetchingUnitsFor = null;
              if (_.has(responseObj.data, 'count')) {
                  self.unitsCount = responseObj.data.count;
                  self.unitsPages = Math.ceil(self.unitsCount / self.SSSearchService.DEFAULT_LIMIT);
                  self.unitsSelectedPage = Math.floor(self.sDb.apiUnitOffset / self.SSSearchService.DEFAULT_LIMIT) + 1;
              }
          }

          self.loadingUnits = false;
      });
  }

  backToProperties() {
    const self = this;

    self.properties = [];
    self.units = [];
    self.fetchingUnitsFor = null;
    self.unitsCount = null;
    self.unitsPages = null;
    self.unitsSelectedPage = null;
    self.sDb.apiSqType = self.SSSearchService.SQ_TYPE.PROPERTIES;
    self.sDb.apiPropertyId = null;
    self.sDb.apiUnitOffset = self.SSSearchService.DEFAULT_OFFSET;
    self.sDb.apiUnitLimit = self.SSSearchService.DEFAULT_LIMIT;

    self.getSearchResults();
  }

  unitsPagesGoBack() {
    const self = this;

    self.unitsSelectedPage--;
    self.sDb.apiUnitOffset = self.sDb.apiUnitOffset - self.sDb.apiUnitLimit;
    self.getSearchResults();
  }

  unitsPagesGoNext() {
    const self = this;

    self.unitsSelectedPage++;
    self.sDb.apiUnitOffset = self.sDb.apiUnitOffset + self.sDb.apiUnitLimit;
    self.getSearchResults();
  }

  getPage(offset, limit, count) {

  }

  search() {
    const self = this;
    window.location.href =self.SSSearchService.getSearchUrlForApi();
//    self.getSearchResults();
  }

  isDistrictsRegionChecked(index) {
    const self = this;

    let districtsRegion = self.districtsRegions[index];
    let districtRegionChecked = true;

    _.forEach(districtsRegion.districts, function (district) {
      if (!self.SSSearchService.isDistrictSelected(district.code)) {
        districtRegionChecked = false;
      }
    });

    districtsRegion.checked = districtRegionChecked;

    return districtRegionChecked;
  }

  districtsRegionChecked(index, checked) {
    const self = this;

    _.forEach(self.districtsRegions[index].districts, function (district) {
      district.checked = checked;
      self.SSSearchService.updateDistricts(district.checked, district.code)
    });
  }

  isMrtLineChecked(index) {
    const self = this;

    let mrtLine = self.mrtLines[index];
    let mrtLineChecked = true;

    _.forEach(mrtLine.stations, function (mrtStation) {
      if (!self.SSSearchService.isMrtStationSelected(mrtStation.id)) {
        mrtLineChecked = false;
      }
    });

    mrtLine.checked = mrtLineChecked;

    return mrtLineChecked;
  }

  mrtLineChecked(index, checked) {
    const self = this;

    _.forEach(self.mrtLines[index].stations, function (mrtStation) {
      mrtStation.checked = checked;
      self.SSSearchService.updateMrtStations(mrtStation.checked, mrtStation.id)
    });
  }

  isSchoolTypeChecked(index) {
    const self = this;

    let schoolType = self.schoolTypes[index];
    let schoolTypeChecked = true;

    _.forEach(schoolType.schools, function (school) {
      if (!self.SSSearchService.isSchoolSelected(school.id)) {
        schoolTypeChecked = false;
      }
    });

    schoolType.checked = schoolTypeChecked;

    return schoolTypeChecked;
  }

  schoolTypeChecked(index, checked) {
    const self = this;

    _.forEach(self.schoolTypes[index].schools, function (school) {
      school.checked = checked;
      self.SSSearchService.updateSchools(school.checked, school.id)
    });
  }

  getMaxPriceOptionValues() {
      const self = this;

      let optionValues = [];

      jQuery.each(self.SSSearchService.pricesReverse, function(i, price) {
          if (price > self.sDb.apiMinPrice) {
              optionValues.push(price);
          }
      });

      return optionValues;
  }
}

CustomerSearchResultsController.$inject = ['$scope', '$location', '$anchorScroll', '$localStorage', 'SSHttpService', 'SSUtilService', 'SSSearchService', 'SSAppService', 'SSUserService', 'SSConfigService'];
app.controller('CustomerSearchResultsController', CustomerSearchResultsController);
